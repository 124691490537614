















































































































































import { Component, Vue } from "vue-property-decorator";
import OfferService from '@/services/OfferService';
import { disciplines, focus } from '@/components/Offer/constants/disciplines.constants'
import { supply_area, supply_mission, admission_restriction } from '@/components/Offer/constants/supply.constants'
import { practice_form } from '@/components/Offer/constants/practiceform.constants';
import { namespace } from "vuex-class";

const ChatModule = namespace("ChatModule");
const Auth = namespace("Auth");

import ChatService from "@/services/ChatService";
import { OfferDto } from "./dtos/offer.dto";

@Component
export default class ShowOffer extends Vue {
  private loading = false;
  private chatId: string | undefined = undefined;
  private offer: OfferDto = new OfferDto();
  private staffAmount = 0;
  
  private wishedSellDateMenu = false;
  
  // load enums
  private disciplines = disciplines;
  private focus = focus;
  private supply_area = supply_area;
  private supply_mission = supply_mission;
  private admission_restriction = admission_restriction;
  private practice_form = practice_form;

  private images = [ { src: '' } ];

  @Auth.State("user")
  private currentUser!: any;

  @ChatModule.State("offerMap")
  private offerMap!: Map<string, string>;

  @ChatModule.Action
  private loadChats!: (userId: string) => Promise<void>;

  get disableChat(): boolean {
    return this.currentUser?.id == null;
  }

  get showChat(): boolean {
    return this.currentUser?.id != this.offer.userId;
  }

  async chat() {
    if (!this.chatId) {
      const chat = await ChatService.createChat({
        initiatorId: this.currentUser?.id, 
        offerId: this.offer?.id
      });
      this.chatId = chat.id;
    }
    this.$router.push(`/chat/${this.chatId}`);
  }

  get showEditOption(): boolean {
    return this.offer?.userId == this.currentUser?.id;
  }

  async editOffer(): Promise<void> {
    this.$router.push(`/edit-offer/${this.$route.params.id}`);
  }

  async mounted() {
    this.offer = await OfferService.getOffer(this.$route.params.id);
    this.staffAmount = (this.offer.practice.hiredDoctors ?? 0) + (this.offer.practice.hiredStaff ?? 0);
    const images = this.offer.images.map(src => {
      return { src };
    });
    this.images = images;
    if(this.currentUser?.id || this.currentUser?.id === this.offer.userId) {
      await this.loadChats(this.currentUser.id);
      this.chatId = this.offerMap.get(this.offer.id ?? '');

}
    
  }
}
